<template>
    <div class="login-page">
        <auth-header>Login</auth-header>
        <div class="login-form-container">
            <div class="login-form">
                <sc-form @submit="submit" :form="form" :padded="false">

                    <template #field-username="{field}">
                        <div class="login-input">
                            <div class="icon">
                                <awesome-icon icon="envelope"></awesome-icon>
                            </div>
                            <input v-model="form.fields.username" placeholder="Email"/>
                        </div>
                    </template>

                    <template #field-password="{field}">
                        <div class="login-input">
                            <div class="icon">
                                <awesome-icon icon="lock"></awesome-icon>
                            </div>
                            <input v-model="form.fields.password" type="password" placeholder="Password"/>
                        </div>
                    </template>

                    <template #buttons>
                        <button class="button" @click="submit">Log in</button>
                    </template>

                </sc-form>

                <div class="reset">
                    <router-link to="/forgot-password" class="reset-button">
                        Forgot/Reset your password?
                    </router-link>
                </div>

                <div class="disclaimer">
                    This computer system and the information it stores and processes are the property of Network Rail.
                    You need specific authority to access any Network Rail system or information and to do so without
                    the relevant authority is a disciplinary offence and may be a breach of the Computer Misuse Act
                    (1990) and lead to criminal prosecution. Employment by or contract to Network Rail does not imply
                    authority to access Network Rail's computer systems.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthHeader from "@/views/auth/components/auth-header";

export default {
  name: 'login',
  components: {AuthHeader},
  data() {
    return {
      form: this.$sform.createForm({
        fields: {
          username: '',
          password: '',
        }
      })
    }
  },

  methods: {
    submit() {
      this.$talker.login({
        email: this.form.fields.username,
        password: this.form.fields.password
      })
        .then(() => {
          let path = (this.$route.query.redirect) ? this.$route.query.redirect : '/app/dashboard';
          let query = (this.$route.query.ei) ? this.$route.query : {};
          query['redirect'] = undefined;
          this.$router.push({path, query})
        })
        .catch((err) => {
          if (err.message) {
            this.$notify.error(err.message);
          } else {
            this.$notify.error(err);
          }

        })
    },
  }

}
</script>

<style scoped lang="scss">
.login-page {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    height: 100%;
    justify-content: center;
    align-items: stretch;

    .login-form-container {
        flex: 1 1 auto;
        margin: auto;
        display: flex;

        .login-form {
            margin: auto;
            width: 600px;
            padding: 28px 0;

            ::v-deep .sc-form {
                form {
                    background: none;
                    box-shadow: none;
                }
            }

            .login-input {
                display: flex;
                border: 1px solid $color-primary;
                border-radius: 0.3em;
                background: #fff;
                margin-bottom: 1em;

                .icon {
                    display: flex;
                    width: 3em;
                    align-items: center;
                    justify-content: center;
                    border-right: 1px solid $color-primary;
                }

                input {
                    border: none;
                    padding: 10px;
                }
            }

            .button {
                color: #fff;
                border: 1px solid #2d97cb;
                background-color: #2d97cb;
                padding: 0.6em 0;
                border-radius: 0.3em;
                font-size: 1.1em;
                width: 100%;
                display: block;
                box-sizing: border-box;
                cursor: pointer;

                &:hover {
                    background: #fff;
                    color: inherit;
                }
            }

            .reset {
                text-align: center;
                margin: 25px 0;

                .reset-button {
                    padding: 5px;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;
                    }
                }
            }

            .disclaimer {
                text-align: center;
                margin-top: 1em;
            }
        }
    }
}


</style>
