<template>
    <div class="header">
        <div class="scala-logo logo" @click="$router.push('/login')">
            <img src="@/img/scala-logo.png"/>
        </div>
        <div class="content">
            <slot></slot>
        </div>
        <div class="nr-logo logo">
            <img src="@/img/nr-logo.png"/>
        </div>
    </div>
</template>

<script>
export default {
  name: "auth-header",

  data() {
    return {}
  },

  computed: {},

  mounted() {

  },

  methods: {}
}
</script>

<style scoped lang="scss">

.header {
    height: 80px;
    background: $color-primary;
    display: flex;
    justify-content: space-between;

    .logo {
        height: 100%;
        flex: 1;

        &.scala-logo {
            display: flex;
            align-items: center;

            img {
                padding-left: 1em;
            }
        }

        &.nr-logo {
            display: flex;
            justify-content: flex-end;
        }

        img {
            max-height: 100%;
        }
    }

    .content {
        flex: 1;
        color: #fff;
        font-size: 1.5em;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}


</style>